// humara main about page divided into sections

//right side ek video aaegi
export const section1 = {
    title: "We make the world work better for everyone",
    content: "Creating a positive impact on organizations of every size and providing faster,smarter and better ways to work"
}

// section 2 mein upar do button aaenge pure horizonatlly faile hue jismein ek mission hoga aur dusra visison. vision par click karke vo open ho jaega
export const section2a = {
    title: "MISSION",
    content: "At CodeKala, our mission is to harness the power of technology to drive positive change in the world"
}

export const section2b ={
    title: "VISION",
    content: "Our vision at CodeKala is to be a global leader in IT services, renowned for our relentless pursuit of excellence and innovation."
}

export const section3 ={
    title: "Our Approach",
    content : "We take the time to understand our clients' unique requirements and objectives, allowing us to tailor our solutions accordingly."
}

export const section4 = {
    title: "JOIN OUR TEAM",
    content: "Are you passionate about technology and innovation? Join our team of experts and embark on a rewarding career journey"
}
//image uploaded hai uske beecho beech aaega yeh aur ek button hoga redirecting to google form