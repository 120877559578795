
//yeh careers page hai aur ismein bhaut saare sections honge toh unko section 1 section 2 ke according divide kia hai

export const section1 = {
    title : "Join us in shaping the future",
    content : "Discover flexible, remote-friendly opportunities and become a part of our mission to make the business side a frictionless experience.",
    buttonContent: "Explore opportunities"
}
//iske right side mein ek picture aa jaegi

export const section2 = {
    title: "Life at codeKala"
}

export const section2Sub = [
    {
        title: "Flexibility",
        content: "Make the most of your life with our flexible work model"
    },
    {
        title: "Career Growth",
        content: "Develop your skills and advance your career alongside a supportive team"
    },
    {
        title: "Collaborative environment",
        content: "Join a dynamic team where collaboration is at the heart of everything we do."
    }
]

export const section3= 
    {
    title: "Connect and Collaborate around the world.",
    content: "Whether you’re customer-facing or fully remote, on Slack or across our global campus, we connect and collaborate in ways that work best for our people and teams."
}
    //iske side mein map laga denge.

export const section4 = {
    content:"Are you ready to embark on an exciting journey with CodeKala? We're always on the lookout for passionate individuals who are eager to contribute to our innovative projects and collaborative culture. If you're someone who thrives in a dynamic environment and loves tackling new challenges, we want to hear from you! ",
    buttonContent : "Join Now"

}

