import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import Backtop from '../../components/backtotop/Backtop'
import { section1, section2, section2Sub, section3, section4 } from '../../data/careers'
import careerSection1 from "../../assets/careerSection1.png";
import careerSection3 from "../../assets/careerSection3.png";
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

const Career = () => {

  const navigate = useNavigate()
  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else {
      navigate('/');
    }
  };

  return (


    <div className='min-h-screen max-w-screen text-slate-300 box-border overflow-x-hidden' >
      <Helmet>
        <title>Careers - CodeKala</title>
      </Helmet>
      <Navbar />
      <div className='h-screen w-screen bg-slate-950 flex flex-col items-start justify-evenly p-10' style={{
        backgroundImage: `url(${careerSection1})`,
        backgroundSize: 'cover',


      }} >
        <h1 style={{ fontFamily: 'Montserrat' }} className='text-6xl text-white' >{section1?.title}</h1>
        <p className='md:w-1/2 w-full md:text-3xl text-2xl text-white' >{section1?.content}</p>
        <button
          class="overflow-hidden border-2 relative md:w-1/4 w-full p-4 text-white  rounded-lg text-xl font-bold cursor-pointer z-10 group"
          onClick={()=>scrollToId('Join-btn-container')}
        >
          {section1?.buttonContent}
          <span
            class="absolute w-full h-32 -top-8 left-0 bg-green-200  transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-bottom"
          ></span>
          <span
            class="absolute w-full h-32 -top-8 left-0 bg-green-400  transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-bottom"
          ></span>
          <span
            class="absolute w-full h-32 -top-8 left-0 bg-green-600  transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-1000 duration-500 origin-bottom"
          ></span>
          <span
            class="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-4 md:left-20 left-[18%] z-10"
          >{section1?.buttonContent}</span>
        </button>

      </div>
      <div className=" w-screen bg-slate-950 flex flex-col items-center pt-20 md:px-20 px-10">
        <h1 style={{ fontFamily: 'Montserrat' }} className='text-6xl text-white'>{section2?.title}</h1>
        <div className="w-full h-full flex flex-wrap md:space-x-4 space-y-4 justify-evenly md:items-baseline py-5">
          {
            section2Sub.map((item) => (
              <div className='md:w-1/4 w-full md:p-5 py-2 md:py-0'>
                <h1 className='text-3xl text-green-300' >{item?.title}</h1>
                <p className='text-lg py-10' >{item?.content}</p>
              </div>
            )
            )
          }
        </div>
      </div>
      <div className='flex md:flex-row flex-col md:px-20 px-10 w-screen bg-slate-950'>
        <div className='md:w-1/2 w-full flex justify-center items-center ' >
          <img className='md:w-2/3 w-full' src={careerSection3} alt="map" />
        </div>
        <div className='flex flex-col md:w-1/2 w-full md:p-20 ' >
          <h1 className='md:text-4xl text-3xl md:p-5 py-5 md:py-0 font-semibold' >{section3?.title}</h1>
          <p className='md:text-xl text-lg md:p-5 py-5 md:py-0' >{section3?.content}</p>
        </div>
      </div>
      <div id='Join-btn-container' className='flex flex-col items-center md:pt-20 pt-10 md:px-40 px-10 w-screen overflow-x-hidden bg-slate-950' >
        <h1 className='text-slate-300 md:text-xl text-md text-center' >{section4?.content}</h1>
        <button onClick={()=>window.open('https://docs.google.com/forms/d/e/1FAIpQLSdpGvAVHx8ksjs1PKpeX3MMsw1zl2SzSEji4SaC-raeW8kTWw/viewform','_blank')} className=' text-white rounded-xl border-2 p-5 m-10 text-lg font-extrabold hover:bg-green-400 hover:border-green-400 transition-all duration-300' >{section4?.buttonContent}</button>
      </div>

      <Footer />
      <Backtop />
    </div>
  )
}

export default Career