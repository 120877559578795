
//servicecard consists of title and tagline for service section. there is a possibi;lty we dont require this

export const serviceCard = 
    {
        title : "Put AI to work",
        content : "Remove complexity and consolidate IT services and tools, bringing together the applications your business needs to deliver modern IT experiences."
    }



// make an array of services of the below structure

export const serviceCardData = [
    {
        serviceName : "Website Development",
        serviceDesc : "Building sleek, user-friendly websites powered by AI-driven optimizations for maximum efficiency and impact."
    },
    {
        serviceName : "Website Designing",
        serviceDesc : "Creating visually stunning and intuitive website interfaces tailored to captivate audiences"
    },
    {
        serviceName: "Instagram Text Automation",
        serviceDesc: "Expand your business with personalized text campaigns to keep your customers engaged."
    },
    {
        serviceName: "Graphic Designing",
        serviceDesc: "Transforming ideas into captivating visuals with precision and creativity. "
    },
    {
        serviceName: "Automation Engine",
        serviceDesc: "Streamlining processes and boosting productivity through intelligent automation solutions. "
    },
    {
        serviceName: "Content Creation",
        serviceDesc: "Elevating your brand's narrative with compelling and AI-enhanced content strategies. "
    },
    {
        serviceName: "Chatbot Integration",
        serviceDesc: "Enhancing customer interactions and support services with intelligent chatbot for various social media platforms. "
    },
    {
        serviceName: "Analytics",
        serviceDesc: "Harnessing the power of data insights to drive informed decision-making and unlock hidden opportunities. "
    },
    {
        serviceName: "Database Management",
        serviceDesc: "Ensuring seamless organization, security, and accessibility of your data assets. "
    },
    {
        serviceName: "Search Engine Optimisation",
        serviceDesc: "Maximizing online visibility and driving organic traffic to your website through SEO strategies. "
    }
];
