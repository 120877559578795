
export const whyUs = 
    {
        title : "Why choose us?"
    }


export const whyUsData = [
    {
        reason : "Scalable Solutions ",
        desc : "Whether you're a startup or a large enterprise, our AI solutions are designed to scale with your business needs, providing flexibility and adaptability for future growth. "
    },
    {
        reason : "Cost effective",
        desc : "We offer competitive pricing for our AI services without compromising on quality, ensuring that you get the best value for your investment "
    },
    {
        reason : "Client-Centric Approach ",
        desc : "Our clients are our top priority, and we go above and beyond to understand their needs, preferences, and goals, fostering long-term partnerships built on trust and mutual success. "
    }
]