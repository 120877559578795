import React, { useState } from 'react'
import { serviceCard, serviceCardData } from '../../data/servicesData';
import Servicecard from '../../components/serviceCard/Servicecard';
import {motion} from "framer-motion"

const Services = () => {


  const [focusedIndex, setFocusedIndex] = useState(null);
  const [isAnyHovered, setisAnyHovered] = useState(false);

  const handleMouseEnter = (index) => {
    setisAnyHovered(true);
    setFocusedIndex(index);
  };

  const handleMouseLeave = () => {
    setisAnyHovered(false);
    setFocusedIndex(null);
  };

  const isMobile = window.innerWidth <= 768; // Assuming mobile width is 768px or less

  const desktopStyle = {
    background: 'rgb(2,6,23,0.6)',
    backgroundImage: 'radial-gradient(circle, rgba(2,6,23,0) 0%, rgba(2,6,23,1) 28%)',
    width: '100%',
    height: '100%',
  };

  const mobileStyle = {
    ...desktopStyle,
    backgroundImage: 'radial-gradient(circle, rgba(2,6,23,0) 0%, rgba(2,6,23,1) 60%)',
  };

  const gridStyle = isMobile ? mobileStyle : desktopStyle;

  
  return (
    
    <div id='service-section' className='min-h-screen overflow-x-hidden px-10 flex flex-col bg-slate-950' >
      <div className="relative text-slate-50 flex flex-col md:p-20 py-10 " style={{ background: 'rgba(2,6,23,1)', opacity: 1, backgroundImage: 'linear-gradient(rgba(34,239,172,1) 2.7px, transparent 2.7px), linear-gradient(to right, rgba(34,239,172,1) 2.7px, rgba(2,6,23,1) 2.7px)', backgroundSize: '40px 40px' }}>
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <div className="w-full h-full" style={gridStyle} ></div>
        </div>
        <div className=' md:text-7xl typewriter text-4xl text-center z-20' style={{
          fontFamily: 'Montserrat',
        }}>{serviceCard?.title}</div>
        <div className='text-center p-5 z-20' >{serviceCard?.content}</div>

      </div>
      <div onMouseLeave={handleMouseLeave} className={`cursor-pointer flex align-middle justify-evenly flex-wrap gap-1`}  >
        {
          serviceCardData.map((ele, index) => (
            <Servicecard key={index} ele={{ ...ele, index }} isAnyHovered={isAnyHovered} isFocused={focusedIndex === index}
              onMouseEnter={() => handleMouseEnter(index)} />
          ))
        }
      </div>
    </div>
  )
}

export default Services

