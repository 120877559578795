
//2nd page ki ai kitna mast hai


export const aiWorld =

    {
        title: "Empower your company with business-ready AI ",
        content: "Using artificial intelligence (AI) to its full potential is now essential for promoting growth and maintaining competitiveness. AI is no longer a futuristic concept but a practical tool transforming industries worldwide. AI is being used by businesses more and more to improve decision-making, expedite procedures, and uncover insightful information. "
    }

