
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Backtop from './components/backtotop/Backtop';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Footer from './components/Footer/Footer';
import WhyChooseUs from './pages/WhyUs/WhyChooseUs';
import Faq from './pages/Faq/FaqSection';

function App() {
  return (
    <div className='min-h-screen box-border overflow-x-hidden text-slate-300' >
      <Navbar/>
      <Home/>
      <About/>
      <WhyChooseUs/>
      <Services/>
      <Faq/>
      <Contact/>
      <Footer/>
      <Backtop/>
    </div>
  );
}

export default App;
