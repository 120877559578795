import React from 'react'
import { section1, section3, section4, section5, section6, section7, section8 } from '../../data/partners'
import partnersSection1 from "../../assets/partnersSection1.jpeg";
import partnersSection3 from "../../assets/partnersSection3.jpeg";
import partnersSection4 from "../../assets/partnersSection4.jpeg";
import partnersSection5 from "../../assets/partnersSection5.jpeg";
import partnersSection6 from "../../assets/partnersSection6.jpeg";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Backtop from "../../components/backtotop/Backtop";
import { Helmet } from 'react-helmet';

const PartnerPage = () => {
    return (
        <>
            <Helmet>
                <title>Partners - CodeKala</title>
            </Helmet>
            <Navbar />
            <div className='min-h-screen max-w-screen flex flex-col bg-slate-950 text-slate-300 box-border overflow-x-hidden' >
                <div className="flex flex-col md:justify-center justify-between space-y-10 items-center h-[569px] m-20" style={{
                    backgroundImage: `url(${partnersSection1})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }} >
                    <p className='md:text-8xl text-5xl text-center font-extrabold text-green-400 backdrop-blur-md' style={{fontFamily:'Montserrat'}} >{section1?.title}</p>
                    <p className='text-3xl font-bold text-white' >{section1?.content}</p>
                    <button onClick={()=>window.open('https://docs.google.com/forms/d/e/1FAIpQLSdo1cgZNXLE8FCiaCEXYfI5g-nAraXjUtYwNO9srac8KYY8RQ/viewform','_blank')} className=' text-white rounded-xl border-2 px-10 p-5 m-10 text-xl font-extrabold bg-green-400 border-green-400 hover:bg-transparent hover:text-green-400 transition-all duration-300' >Join Us</button>


                </div>
                <div className='flex flex-col py-20 md:px-40'>
                <div className="flex md:flex-row flex-col ">
                    <div className="flex flex-col md:text-end text-justify md:p-20 px-20 ">
                        <p className='text-4xl font-semibold mb-5' >{section3?.title}</p>
                        <p>{section3?.content}</p>
                    </div>
                    <div className="flex md:w-1/3 w-full p-20 md:p-0 justify-center items-center">
                        <img className='rounded-xl' src={partnersSection3} alt="partnerPageImg" />
                    </div>
                </div>
                <div className="flex md:flex-row flex-col-reverse ">
                    <div className="flex md:w-1/3 w-full p-20 md:p-0 justify-center items-center">
                        <img className='rounded-xl' src={partnersSection4} alt="partnerPageImg" />
                    </div>
                    <div className="flex flex-col md:text-start text-justify md:p-20 px-20 ">
                        <p className='text-4xl font-semibold mb-5'>{section4?.title}</p>
                        <p>{section4?.content}</p>
                    </div>
                </div>
                <div className="flex md:flex-row flex-col">
                    <div className="flex flex-col md:text-end text-justify md:p-20 px-20 ">
                        <p className='text-4xl font-semibold mb-5'>{section5?.title}</p>
                        <p>{section5?.content}</p>
                    </div>
                    <div className="flex md:w-1/3 w-full p-20 md:p-0 justify-center items-center">
                        <img className='rounded-xl' src={partnersSection5} alt="partnerPageImg" />
                    </div>
                </div>
                <div className="flex md:flex-row flex-col-reverse">
                    <div className="flex md:w-1/3 w-full p-20 md:p-0 justify-center items-center">
                        <img className='rounded-xl' src={partnersSection6} alt="partnerPageImg" />
                    </div>
                    <div className="flex flex-col md:text-start text-justify md:p-20 px-20 ">
                        <p className='text-4xl font-semibold mb-5'>{section6?.title}</p>
                        <p>{section6?.content}</p>
                    </div>
                </div>
                </div>
                <div className="text-center md:text-5xl text-3xl text-slate-950 font-bold md:py-20 py-10 bg-green-200 md:mt-10" style={{fontFamily:'Montserrat'}}>{section7?.title}</div>
                <div className="text-center text-2xl text-slate-300 p-10 "><a href="mailto:codeKala.info@gmail.com" className='underline underline-offset-2'>Reach us out at - codeKala.info@gmail.com</a></div>
            </div>
            <Footer />
            <Backtop />
        </>
    )
}

export default PartnerPage