import React, { useRef } from 'react'
import { whyUs,whyUsData } from '../../data/whyUs'
import WhyUsCard from '../../components/whyUsCard/whyUsCard'
import {motion, useInView} from "framer-motion"

const WhyChooseUs = () => {
  const divRef = useRef(null);
  const inView = useInView(divRef , {once:true});

  return (
    <div ref={divRef} id='why-us-section' className="min-h-screen w-screen bg-slate-950">
        <div
        
        className="flex justify-center align-middle md:p-20 md:text-6xl text-4xl p-10 text-gray-50" style={{
            fontFamily: 'Montserrat',
          }}>{whyUs?.title.slice(0,-1)}<motion.span className='text-green-300' animate={{ rotateY: [0,360] }}
          transition={{ duration: 2, ease: "linear", repeatDelay:0, repeat:Infinity }} 
          > {whyUs?.title.slice(-1)}</motion.span> </div>
        <motion.div
        variants={{
          hidden: {opacity:0},
          show:{opacity:1, transition :{staggerChildren:0.75 ,ease:'easeInOut', delay:'0.5'}},
        }} 
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        className="flex flex-col md:flex-row md:p-20 p-10 md:space-x-10 md:space-y-0 space-y-20 ">
          {whyUsData.map((item,index) => (
              <WhyUsCard item={{...item,index}} key={index} />
          ))}
        </motion.div>
    </div>
  )
}



export default WhyChooseUs