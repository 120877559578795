import React from 'react'
import logo from '../../assets/logo.jpeg'
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
    //contact-section
    const navigate = useNavigate()
    const scrollToId = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        else {
            navigate('/');
        }
    };

    return (
        <div id='navbar' className="navbar text-slate-300 bg-slate-950">

            <div tabIndex={0} className="btn btn-circle avatar">
                <div className="w-10 rounded-full">
                    <img alt="Tailwind CSS Navbar component" src={logo} />
                </div>
            </div>

            <div className="flex-1 p-3 ">
                <p onClick={() => navigate('/')} className="text-2xl cursor-pointer">codeKala</p>
            </div>
            <ul className="hidden md:flex menu menu-horizontal px-1 text-xl">
                <li><p onClick={() => navigate('/about')} >About Us</p></li>
                <li><p onClick={() => navigate('/careers')} >Careers</p></li>
                <li><p onClick={() => navigate('/partner')} >Become a Partner</p></li>
                <li><p onClick={() => scrollToId('contact-section')} >Contact</p></li>
            </ul>
            <div className="dropdown md:hidden">
                <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                </div>
                <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 right-0">
                    <li><p onClick={() => navigate('/about')} >About Us</p></li>
                    <li><p onClick={() => navigate('/careers')} >Careers</p></li>
                    <li><p onClick={() => navigate('/partner')} >Become a Partner</p></li>
                    <li><p onClick={() => scrollToId('contact-section')} >Contact</p></li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar