
export const section1 = {
    title: "We believe in power of collaboration",
    content: "Together, we can achieve more and drive innovation that transforms industries."
}

export const section2= {
    title: "A partnership built on equality and trust",
    content: "Become more than a partner. Work side by side with CodeKala and together drive growth and opportunity."
}

// 3-6 tak ek side image aaegi aur dusri side content
export const section3={
    title: "Unlock opportunities",
    content: " Partnering with CodeKala opens doors to new markets, industries, and revenue streams. Together, we can explore untapped opportunities and drive growth."
}

export const section4 = {
    title : "Access to Expertise",
    content: "Benefit from our expertise in AI, web development, and more. Whether you're a startup or an established company, our team is here to support your success."
}

export const section5 = {
    title: "Co-Marketing Initiatives",
    content: "Let's amplify our reach together. As partners, we'll collaborate on marketing initiatives to showcase our joint offerings and attract new clients."
}

export const section6 = {
    title: "Collaborative innovations",
    content: "Join us in shaping the future of technology. Through strategic alliances and co-development projects, we can create groundbreaking solutions that make a difference."
}

export const section7 = {
    title: "Alone we can do so little; together we can do so much."
}//ek line mein bass yeh likhdo

export const section8 = {
    title:"Ready to explore partnership opportunities with CodeKala? Fill out the form below to express your interest, and our partnership team will get in touch with you shortly."
}