import React, { useState } from 'react'


const Servicecard = ({ele, index, isFocused, onMouseEnter , isAnyHovered}) => {
  const blurClass = isFocused || !isAnyHovered ? '' : 'blur-lg scale-75';
  const isEven = ele.index % 2 === 0;
  

  return (
    <div onMouseEnter={onMouseEnter} className={`${blurClass} border transition-all duration-300 border-green-300 ${isEven ? 'bg-slate-950' : 'bg-green-300 text-black'} rounded-3xl w-full md:w-1/6 md:mb-4 my-3 flex flex-col p-4 gap-4 align-middle`} >
        <p className='text-center text-lg font-bold'>{ele?.serviceName}</p>
        <p className='text-center font-semibold' >{ele?.serviceDesc}</p>
    </div>
  )
}

export default Servicecard