
export const faq= [
    {
        title: "What services does CodeKala offer?",
        content: "We offer a wide range of IT services, including website development, process automation, chatbot creation and more"
    },
    {
        title: "How can I get started with CodeKala?",
        content: "simply contact us by mailing on codekala.info@gmail.com to discuss your project requirements and specifications."
    },
    {
        title: "What industries does CodeKala work with?",
        content: "We work with clients across various industries, including healthcare, finance, e-commerce, education, and beyond."
    },
    {
        title: "How can AI benefit my business?",
        content: "AI can benefit your business in numerous ways, such as improving operational efficiency, enhancing customer experience, automating repetitive tasks, gaining valuable insights from data, and driving innovation."
    },
    {
        title:"What web development services does CodeKala offer?",
        content: "Our web development services include creating responsive websites, e-commerce platforms, custom web applications and more"
    }
]