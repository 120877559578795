
//about our company

export const aboutUs = 
    {
        title: "CodeKala: Pioneering AI Solutions for the Modern World",
        content: "Unlock the full potential of your business with our business-ready AI solutions.Our single platform for digital business delivers unmatched opportunity. Built for a fast-changing world, the Platform connects people and data for greater productivity and innovation. Put AI to work and unleash your company’s full potential "

    }

    


