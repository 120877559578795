import React from 'react'
import aboutSection1 from '../../assets/aboutSection1.mp4';
import aboutSection4 from '../../assets/aboutSection4.png';
import { section1, section2a, section2b, section3 } from '../../data/about';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Backtop from '../../components/backtotop/Backtop';
import { Helmet } from 'react-helmet';
import logo from "../../assets/logo.jpeg";

const AboutDetailed = () => {
    return (
        <div className='min-h-screen max-w-screen text-slate-300 bg-slate-950 overflow-x-hidden'>
            <Helmet>
                <title>About Us - CodeKala</title>
            </Helmet>
            <Navbar />
            <div className="flex md:flex-row flex-col md:h-screen md:p-20 p-10" >
                <div className='md:w-1/2 w-full flex flex-col justify-center space-y-6 '>
                    <p className='md:text-7xl text-4xl font-bold text-end' >{section1?.title}</p>
                    <p className='text-xl text-end' >{section1?.content}</p>
                </div>
                <div className="md:flex hidden justify-center items-center w-1/2">
                    <img src={logo} alt="logo" className='w-1/2' />
                </div>
            </div>
            <div className="flex md:h-screen flex-col gap-10 md:p-20 p-10 ">
                <div className="flex md:flex-row flex-col gap-10 justify-around">
                    <p className='md:w-1/3 w-full md:text-7xl text-4xl font-bold' >Our Mission & Vision</p>
                    <div className='flex md:w-1/2 w-full items-center rounded-full shadow-lg shadow-slate-800'>
                        <img className=' rounded-full h-[160px] aspect-square' src="https://cdn-icons-png.freepik.com/512/8771/8771828.png" alt="" />
                        <div className='flex flex-col md:px-10'>
                            <p className='font-bold md:text-2xl text-lg text-green-300' >{section2a?.title}</p>
                            <p className='md:text-lg text-sm' >{section2a?.content}</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-start md:p-10 ">
                    <div className='flex md:w-1/2 w-full rounded-full shadow-lg shadow-slate-800'>
                        <div className='flex flex-col justify-center items-end md:px-10'>
                            <p className='font-bold md:text-2xl text-lg text-green-300'>{section2b?.title}</p>
                            <p className='text-end md:text-lg text-sm' >{section2b?.content}</p>
                        </div>
                        <img className='rounded-full h-[160px] aspect-square' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcXOXyKAHl5gwAVI3-yuNqqlNJGL_nY3-loNQ3vHC0Xw&s" alt="" />
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center justify-evenly' >
                <p className='md:text-7xl text-5xl font-bold' >{section3?.title}</p>
                <p className='text-xl md:p-20 p-10' >{section3?.content}</p>

            </div>
            <Footer />
            <Backtop />
        </div>
    )
}

export default AboutDetailed