import React, { useRef } from 'react'
import {aboutUs} from '../../data/aboutUs';
import {motion, useInView} from "framer-motion"

const About = () => {
  const divRef = useRef(null);
  const inView = useInView(divRef , {once:true})

  return (
    <div ref={divRef} id='about-section' className="h-screen w-screen bg-slate-950">
      <div className="relative md:block hidden opacity-40 -left-36 top-32 w-2/5 bg-gradient-to-r from-white to-green-300 p-10 [box-shadow:_10px_10px_150px_20px_#87efab]" style={{ filter: 'blur(30px)', transform: 'rotate(20deg)' }}></div>
      <div className='flex flex-col justify-evenly h-5/6 w-full p-8' >
        <div className='' >
          <motion.p
          variants={{
            hidden: {opacity:0 , y:75},
            visible:{ opacity:1 ,y:0}
           }}
           initial="hidden"
           animate= {inView ? "visible" : "hidden"} 
           transition={{duration:0.9 , delay:0.5}} 
          className='md:text-6xl text-5xl text-center text-gray-50 ' style={{
            fontFamily: 'Montserrat',
          }} ><span style={{
            fontFamily: 'Montserrat', background: '#F0F2F1',
            background: 'linear-gradient(to right, #F0F2F1 0%, #74C69D 60%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }} >{aboutUs?.title?.split(":")[0]}:</span  >{aboutUs?.title?.split(":")[1]}</motion.p>
        </div>
        <div className=' md:px-40 text-center text-lg' >
          <motion.p
          variants={{
            hidden: {opacity:0 , y:75},
            visible:{ opacity:1 ,y:0}
           }}
           initial="hidden"
           animate= {inView ? "visible" : "hidden"} 
           transition={{duration:0.9 , delay:1}} 
          >{aboutUs?.content}</motion.p>
        </div>
      </div>
    </div>

  )
}

export default About