import React from 'react'
import dollar from "../../assets/dollar.png"
import scalable from "../../assets/scalable.png"
import client from "../../assets/client.png"
import {motion} from "framer-motion"


const WhyUsCard = ({ item, index }) => {

    return (
        <motion.div
        variants={{hidden : {opacity:0,y:75} , show:{opacity:1,y:0}}}
        transition={{duration : 0.75}} 
         className="relative md:w-1/3 w-full flex flex-col p-10 justify-center items-center rounded-lg bg-white bg-opacity-15 shadow-xl backdrop-blur-md">
            {item.index === 0 && <img className='absolute -top-10 w-[80px] aspect-square rounded-full' style={{
                boxShadow: '0px 0px 50px 20px rgba(72, 187, 120, 0.5)',
            }} src={scalable} alt="" />}
            {item.index === 1 && <img className='absolute -top-10 w-[80px] aspect-square rounded-full' style={{
                boxShadow: '0px 0px 50px 20px rgba(244, 114, 182, 0.5)',
            }} src={dollar} alt="" />}
            {item.index === 2 && <img className='absolute -top-10 w-[80px] aspect-square rounded-full' style={{
                boxShadow: '0px 0px 50px 20px rgba(66, 153, 225, 0.5)',
            }} src={client} alt="" />}
            <p className='text-2xl font-bold m-5 text-white ' >{item?.reason}</p>
            <p className='h-full p-5 font-semibold text-center ' >{item?.desc}</p>
        </motion.div>
    )
}

export default WhyUsCard