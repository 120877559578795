import React, { useState } from 'react';
import './contact.css';
import { PiPhoneCallBold } from "react-icons/pi";
import { MdOutlineMailOutline } from "react-icons/md";
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';


const Contact = () => {

  const [Message, setMessage] = useState("");
  const [Email, setEmail] = useState("");
  const [Name, setName] = useState("");

  const sendEmail = () => {

    if (!Email) {
      return toast.error("Email can't be Empty!", {
        style: {
          background: '#1D232A',
          color: 'whitesmoke'
        }
      });
    }

    const templateparams = {
      from_name: Name,
      from_email: Email,
      to_name: 'Codekala',
      message: Message,

    }


    emailjs.send('service_di7u7m1', 'template_pg9mvxk', templateparams, '0dNWjLjBZQpHpf2Ar')
      .then((response) => {

        toast.success('Email sent ! We will get back to you shortly :)', {
          duration: 3000, // Duration in milliseconds (3 seconds)
          style: {
            background: '#1D232A',
            color: 'whitesmoke'
          },
        });
        setMessage('');
        setEmail('');
        setName('');
      }, (error) => {
        console.log(error.text);
        toast.error('We are swamped currently, please try later :(', {
          style: {
            background: '#1D232A',
            color: 'whitesmoke'
          }
        });
      });
  };
  return (
    <div id='contact-section' className="flex items-center justify-center w-screen h-screen bg-slate-950 " >
      <div className="flex flex-wrap justify-center w-full md:px-60 bg-slate-950 rounded-lg overflow-hidden shadow-lg z-10">

        <div className="md:w-1/2 w-full bg-slate-950 text-white p-3 flex flex-col md:items-end items-center">
          <h2 className="md:text-9xl text-6xl font-bold mb-4 p-3 md:text-end text-end text-transparent bg-clip-text bg-contain bg-[url('https://happinessgroup.eu/wp-content/uploads/2016/01/Fotolia_89102505_L.jpg')]" style={{ filter: 'brightness(1.1)' }}>Contact Us</h2>

          <div className="flex items-center justify-end">
            <MdOutlineMailOutline style={{ fontSize: '24px', color: 'rgb(134,239,172)' }} />
            <p className="text-xl p-3">codekala.info@gmail.com</p>
          </div>
          {/* <div className="flex items-center justify-end">
            <PiPhoneCallBold style={{ fontSize: '24px', color: 'rgb(134,239,172)' }} />
            <p className="text-xl p-3">+91 </p>
          </div> */}
          <div className="flex items-center justify-end space-x-4 p-3">
            <a href='https://twitter.com/Codekala__' className='text-green-300 hover:text-green-500' ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
            </svg></a>
            <a href='https://www.instagram.com/codekala__/' className='text-green-300 hover:text-green-500' ><svg width="24" height="24" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" className='fill-current'><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" /></svg></a>
            <a href='https://www.linkedin.com/company/codekala/' className='text-green-300 hover:text-green-500' ><svg xmlns="http://www.w3.org/2000/svg" role='img' width="24" height="24" viewBox="0 0 24 24" className='fill-current'><path d="M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z" /></svg>
            </a>
          </div>
        </div>


        <div className="md:w-1/2 w-full p-8 m-10 md:m-0 flex flex-col justify-evenly space-y-4 bg-slate-950 form-container">
          <label className="input input-bordered flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" /></svg>
            <input onChange={(e) => setName(e.target.value)} type="text" className="grow text-slate-300" placeholder="Name" value={Name} name='Name' />
          </label>
          <label className="input input-bordered flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
            <input onChange={(e) => setEmail(e.target.value)} type="text" className="grow text-slate-300" placeholder="Email" value={Email} name='Email' />
          </label>
          <label className="input input-bordered flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
            <input onChange={(e) => setMessage(e.target.value)} type="text" className="grow text-slate-300" placeholder="Message" value={Message} name='Message' />
          </label>
          <label className="label cursor-pointer justify-start ">
            <input type="checkbox" defaultChecked className="checkbox checkbox-accent" />
            <span className="label-text mx-2 text-gray-100">Subscribe to our Newsletter</span>
          </label>
          <button onClick={sendEmail} className="group group-hover:before:duration-500 group-hover:after:duration-500 after:duration-500 hover:border-green-300 hover:before:[box-shadow:_20px_20px_20px_30px_#86ade6] duration-500 before:duration-500 hover:duration-500 hover:after:-right-8 hover:before:right-12 hover:before:-bottom-8 hover:before:blur origin-left hover:text-green-300 relative bg-slate-950 h-16 w-72 border-2 text-center p-3 text-gray-50 text-lg font-bold rounded-lg  overflow-hidden  before:absolute before:w-12 before:h-12 before:content[''] before:right-1 before:top-1 before:z-10 before:bg-blue-300 before:rounded-full before:blur-lg  after:absolute after:z-10 after:w-20 after:h-20 after:content['']  after:bg-green-300 after:right-8 after:top-3 after:rounded-full after:blur-lg">
            Connect
          </button>


        </div>
      </div>

    </div>

  )
}

export default Contact

