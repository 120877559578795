import React from 'react'
import { faq } from '../../data/faq'

const Faq = () => {
  return (
    <div className='w-screen md:p-20 p-10 bg-slate-950 flex flex-col items-center justify-evenly space-y-4' >
      <h1 className='text-5xl font-bold my-5 md:text-green-300 text-slate-300' ><span className="text-green-300">F</span>requently <span className="text-green-300">A</span>sked <span className="text-green-300">Q</span>uestions</h1>
      {
        faq.map((item,index) => (
          <div key={index} className="collapse shadow-md shadow-slate-700 collapse-arrow bg-slate-950 text-slate-300">
            <input type="radio" name="my-accordion-2"/>
            <div className="collapse-title text-xl font-semibold">
              {item?.title}
            </div>
            <div className="collapse-content">
              <p className='text-green-300' >{item?.content}</p>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Faq