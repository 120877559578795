import React, { useRef } from 'react'
import background  from '../../assets/background.png';
import { aiWorld } from '../../data/aiWorld';
import { animate, motion,useInView } from 'framer-motion';


const Home = () => {

  const imageStyle = {
    filter: 'drop-shadow(0 0 15px #00ff00)',
  };

  const divRef = useRef(null);
  const isInView = useInView(divRef , {once:true})


  return (
    <div className="hero max-w-screen md:h-screen bg-slate-950">
      <div className="hero-content flex-col md:flex-row-reverse items-center">
        <img src={background} style={imageStyle} className=" w-full rounded-lg" alt='spiral'/>
        <div ref={divRef} className='md:w-max w-full' >
          <motion.h1
           variants={{
            hidden: {opacity:0 , y:75},
            visible:{ opacity:1 ,y:0}
           }}
           initial="hidden"
           animate="visible"
           transition={{duration:0.9 , delay:0.25}}

           className="text-5xl font-bold">{aiWorld?.title}</motion.h1>
          <motion.p
          variants={{
            hidden: {opacity:0 , x:75},
            visible:{ opacity:1 ,x:0}
           }}
           initial="hidden"
           animate="visible"
           transition={{duration:0.9 , delay:1}} 
           className="py-6">{aiWorld?.content}</motion.p>
        </div>
      </div>
    </div>
  )
}

export default Home