import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Career from './pages/Career/Career';
import AboutDetailed from './pages/AboutDetailed/AboutDetailed';
import PartnerPage from './pages/Partner/PartnerPage';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/about" element={<AboutDetailed />} />
        <Route path="/partner" element={<PartnerPage />} />
      </Routes>
      <Toaster
        position="top-center"
        reverseOrder={false}
        
      />
    </BrowserRouter>
  </React.StrictMode>
);


