import React from 'react'

const Backtop = () => {

    const  handlebacktop = () => {
        const element = document.getElementById('navbar');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <button onClick={handlebacktop} className="fixed bottom-10 bg-green-300 right-10 rounded-full p-2 shadow-2xl shadow-green-300 z-50">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-slate-950" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
            </svg>
        </button>
    )
}

export default Backtop